#loading-screen {
  background-color:#001816;
}

#full-screen {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  background: green;
  overflow: hidden;
  display: flex;
  margin: 0;
}
#intro-container {
  width: 100%;
  height: 100vh;
  display: block;
  margin: 0;
  top: 0;
}
#intro-container-2 {
  width: 100%;
  height: 100vh;
  display: none;
  margin: 0;
  top: 0;
  left: 0;
  position: fixed;  
  background-color:#000000;
  opacity:0;
  z-index: 999;
}
#intro-main {
  display: flex;
  width: auto;
  height: 100vh;
}



.top-buttons-container {
  width: 100%;
  height: auto;
  position: fixed;
  background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 24%, rgba(0,0,0,0) 100%);
  padding: 10px 10px 5px 14px;
  vertical-align: middle;
  display: block;
  /* background-color:gold; */
}

#top-buttons {
  top: 0;
  left: 0;
  z-index:9999;
  color:#ffffff;
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
}
.bottom-buttons-new-container{
  width:100%;
  height:750px;
  position:absolute;
  left:0;
  top:0;
}

#bottom-buttons {
  position:absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;  
  background-color: rgba(154, 154, 216, 0.6);
  padding: 1rem;
  vertical-align: middle;
  display: inline-block;
}

#progress-buttons {
  max-width: 1244px;
  width: 100%;
  display: flex;
  align-content: stretch;
  margin: 0 auto;
  font-size:1.125em;
}
#progress-buttons > * {
  flex-grow: 1;
}

.progress-leg-container {
  display: block;
  height: auto;
}
.progress-leg-container button {
  display: block;
  position: relative;
  margin: 0 auto;
  width: fit-content;
  padding: 15px 15px 15px 15px;
  background-color: orchid;
  min-width: 200px;
  text-align: center;  
  cursor: pointer;
  font-size:1.125rem;
}

#sound-buttons {
  width: auto;
  display: none;
  margin: 0;
}

#sound-buttons btn:not(.active) {
  display: none;
}
#language-buttons btn:not(.active) {
  display: none;
}

.sound-button-container {
  float: left;
  padding-left:4px;
  padding-top:2px;
}
.sound-button-container btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-right: 7px;
}
.sound-button-container img {
  height: 20px;
  width: auto;
}

.fx{
  width:auto;  
  margin-left: 6px;
}
.background-sound{
  width:auto;
}

.fx, .background-sound{  
  padding-top:1px;
  padding-left: 8px;
}

.fx span, .background-sound span{
  display:inline-block;
  width: auto;
}

.toggle-box{
  display:inline-block;
  /* margin-right:7px; */
  position: relative;

}
.toggle-bg{
  background-color:#ffffff;
  border-radius:50%;
  width:14px;
  height:14px;
  position:relative;
  margin-top: 5px;

}
.toggle-fg{
  width:14px;
  height:14px;
  position:absolute;
  top:0;
  left:0;
}

.lang-container{
  width:72px;
  height:auto;
  border:2px solid #ffffff;
  border-radius: 6px;
}

.lang-container #eng-btn{
  padding-left:10px;
}
.lang-container #tereo-btn{
  padding-left:11px;
}




#master-container,
#intro-content-container {
  z-index: 2000;
  width: 100vw;
  height: 750px;
  /* align-self: center; */
  align-self: flex-start;
  margin: 0 auto;
  /* can make smaller than 1244px if needed */
  /* min-width: 1244px; */
  min-width:800px;
}

#master-container {
  display: block;
  background: red;
  overflow-y: hidden;
}

#intro-content-container {
  display: flex;
  margin-right: .5em;
  /* background-color:orange; */
}

.title-content {
  text-align: left;
  flex-basis: 50%;
  display: block;
}
.titles {
  display: flex;
    flex-direction: row;
    justify-content:flex-start;
    height: 100%;
    align-items: center;
}
.title-grouper {
  position: relative;
  width: 100%;
  height: auto;
  text-align:center;
  max-width: 622px;
  
}

.title-grouper h1, .title-grouper h2, .title-grouper p{
  display:inline-block;
  /* remove non-collapsing margins */
  margin-bottom:0;
  margin-top:0;
  font-weight: unset;
  
}

.title-grouper h1, .title-grouper h2{
  
  
}

.title-grouper h1{
 font-size:4rem;
 letter-spacing: -0.02em;
 line-height: 105%; 
 text-transform:uppercase;
 color:#fff0c4;
}
.title-grouper h2{
  font-size: 1.8rem;
  line-height: 120%;  
  margin-bottom:20px;
  color:#fff0c4;
  letter-spacing:0.02em;
}

.title-grouper:not(.active){
  display: none;
}

.nuku-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  align-items: flex-end;
}

.nuku-img-wrapper {
  width: 440px;
  overflow: hidden;
  margin-right: 50px;
}

img#nuku-title-profile {
  width: 980px;
  height: auto;
  display: block;
  position: relative;
  top: 50px;
  right: 72%;
}

.audio-message-container p{
  font-size: 1.25rem; 
  color:#fff0c4; 
  font-weight:unset;
  letter-spacing:0.02em;
  line-height: 130%;
}
.disclaimer {
  /* text-align: left; */
  font-size: 16px;
  line-height: 1.25;
  margin-top: 2em;
}
.disclaimer a {
  color: darkorange;
}
.disclaimer p+p {
  margin-top: 1em;
}
.back-to-menu-container{
  display:none;
}
.credits-container{
  display:none;
}

.loading-button-container{
  height:120px;
  width:100%;
  position: relative;
  margin-top:40px;
}

.loading-indicator{
  display: block;  
  margin-left: auto;
  margin-right: auto;
  font-size: 3rem;
  font-weight: unset;
  margin-bottom: 0;
  margin-top: 0px;

}

.btnStart{
  /* position: absolute;
    top: 0;
    left: 0; */
    margin-top: 10px;
}

#btnStart {
  display: none;
  font-family: sriracha;
  padding: .6em 1.2em .6em 1.2em;
  font-size: 1.5rem;
  background-color: #008c58;
  border-radius: 0.5em;
  color:#ffffff;
  border: 0px solid #000000;
  cursor:pointer;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.8);
}
#btnStart:hover{
    background-color: #00985f; 
    /* transform: scale(1.005);  */
    cursor:pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.8);
  }

.round-wrapper {
  height: 440px;
  margin-top: 152px;
  border-radius: 50%;
  border: 3px solid;
}


.loading-spinner {
  margin-left: 0.3em;
  display: inline-block;
  animation: rotation 1s ease-in-out infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#loading-screen {
  background-color: #001816;
}

#full-screen {
  width: 100%;
  height: 100vh;
  background: green;
  margin: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

#intro-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: block;
  top: 0;
}

#intro-container-2 {
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 999;
  background-color: #000;
  margin: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#intro-main {
  width: auto;
  height: 100vh;
  display: flex;
}

.top-buttons-container {
  width: 100%;
  height: auto;
  vertical-align: middle;
  background: linear-gradient(#0009 0% 24%, #0000 100%);
  padding: 10px 10px 5px 14px;
  display: block;
  position: fixed;
}

#top-buttons {
  z-index: 9999;
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  top: 0;
  left: 0;
}

.bottom-buttons-new-container {
  width: 100%;
  height: 750px;
  position: absolute;
  top: 0;
  left: 0;
}

#bottom-buttons {
  width: 100%;
  height: auto;
  vertical-align: middle;
  background-color: #9a9ad899;
  padding: 1rem;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

#progress-buttons {
  max-width: 1244px;
  width: 100%;
  align-content: stretch;
  margin: 0 auto;
  font-size: 1.125em;
  display: flex;
}

#progress-buttons > * {
  flex-grow: 1;
}

.progress-leg-container {
  height: auto;
  display: block;
}

.progress-leg-container button {
  width: fit-content;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  background-color: orchid;
  margin: 0 auto;
  padding: 15px;
  font-size: 1.125rem;
  display: block;
  position: relative;
}

#sound-buttons {
  width: auto;
  margin: 0;
  display: none;
}

#sound-buttons btn:not(.active), #language-buttons btn:not(.active) {
  display: none;
}

.sound-button-container {
  float: left;
  padding-top: 2px;
  padding-left: 4px;
}

.sound-button-container btn {
  cursor: pointer;
  margin-right: 7px;
  display: inline-block;
  position: relative;
}

.sound-button-container img {
  height: 20px;
  width: auto;
}

.fx {
  width: auto;
  margin-left: 6px;
}

.background-sound {
  width: auto;
}

.fx, .background-sound {
  padding-top: 1px;
  padding-left: 8px;
}

.fx span, .background-sound span {
  width: auto;
  display: inline-block;
}

.toggle-box {
  display: inline-block;
  position: relative;
}

.toggle-bg {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 5px;
  position: relative;
}

.toggle-fg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.lang-container {
  width: 72px;
  height: auto;
  border: 2px solid #fff;
  border-radius: 6px;
}

.lang-container #eng-btn {
  padding-left: 10px;
}

.lang-container #tereo-btn {
  padding-left: 11px;
}

#master-container, #intro-content-container {
  z-index: 2000;
  width: 100vw;
  height: 750px;
  min-width: 800px;
  align-self: flex-start;
  margin: 0 auto;
}

#master-container {
  background: red;
  display: block;
  overflow-y: hidden;
}

#intro-content-container {
  margin-right: .5em;
  display: flex;
}

.title-content {
  text-align: left;
  flex-basis: 50%;
  display: block;
}

.titles {
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.title-grouper {
  width: 100%;
  height: auto;
  text-align: center;
  max-width: 622px;
  position: relative;
}

.title-grouper h1, .title-grouper h2, .title-grouper p {
  font-weight: unset;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.title-grouper h1 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff0c4;
  font-size: 4rem;
  line-height: 105%;
}

.title-grouper h2 {
  color: #fff0c4;
  letter-spacing: .02em;
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 120%;
}

.title-grouper:not(.active) {
  display: none;
}

.nuku-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.nuku-img-wrapper {
  width: 440px;
  margin-right: 50px;
  overflow: hidden;
}

img#nuku-title-profile {
  width: 980px;
  height: auto;
  display: block;
  position: relative;
  top: 50px;
  right: 72%;
}

.audio-message-container p {
  color: #fff0c4;
  font-size: 1.25rem;
  font-weight: unset;
  letter-spacing: .02em;
  line-height: 130%;
}

.disclaimer {
  margin-top: 2em;
  font-size: 16px;
  line-height: 1.25;
}

.disclaimer a {
  color: #ff8c00;
}

.disclaimer p + p {
  margin-top: 1em;
}

.back-to-menu-container, .credits-container {
  display: none;
}

.loading-button-container {
  height: 120px;
  width: 100%;
  margin-top: 40px;
  position: relative;
}

.loading-indicator {
  font-size: 3rem;
  font-weight: unset;
  margin: 0 auto;
  display: block;
}

.btnStart {
  margin-top: 10px;
}

#btnStart {
  color: #fff;
  cursor: pointer;
  background-color: #008c58;
  border: 0 solid #000;
  border-radius: .5em;
  padding: .6em 1.2em;
  font-family: sriracha;
  font-size: 1.5rem;
  display: none;
  box-shadow: 0 4px 8px #000c;
}

#btnStart:hover {
  cursor: pointer;
  background-color: #00985f;
  box-shadow: 0 8px 16px #000c;
}

.round-wrapper {
  height: 440px;
  border: 3px solid;
  border-radius: 50%;
  margin-top: 152px;
}

.loading-spinner {
  margin-left: .3em;
  animation: rotation 1s ease-in-out infinite;
  display: inline-block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/*# sourceMappingURL=index.1a13b705.css.map */
